<template>
  <div id="app">
    <!-- NavBar that hovers over the page content -->
    <NavBar />

    <!-- <SinglePage></SinglePage> -->
    <router-view></router-view>

  </div>
</template>

<script>
// Importing NavBar
import NavBar from './components/NavBar.vue';
import SinglePage from './views/SinglePage.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    SinglePage
  },
};
</script>

<style lang="scss">

// Main Settings

body{
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  height: 100vh; /* Full viewport height */
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative; /* For proper stacking of elements */
}

// Fonts

p.hero-paragraph {
  font-size: 1.2rem;
  line-height: 2rem;
}

h1.hero-heading {
  font-size: 3.5rem;
  color: antiquewhite; /* Slightly off-white color */

}

.pt-sans-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.pt-sans-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}




</style>
