<template>
  <div class="about">
    <div class="content">
      <div class="left-content">
        <div class="hero-info pt-sans-regular">
          <h1 class="hero-heading">How it came to life</h1>
          <div class="hero-info-text-box">
            <p class="hero-paragraph">
              Benevnuti to my open portfolio and start-up Brand Francesca Fiore!
              <br><br>Since I was a teenager I always bought fashion magazines to read on the train and drew inspiration from couture runway shows.
              <br><br>I am so thankful, that I pursued to study Fashion Design and build a strong network of people in various professions.
              <br><br>With a great team, amazing projects come to life.
              <br><br>During the process of choosing the topic, sketching, writing, organising the Photoshooting location, and finding the models <b>a story is being told.</b>
            </p>
          </div>
        </div>
      </div>
      <div class="right-content">
        <!-- You can add content here if needed -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.about {
  width: 100%;
  min-height: 100vh; /* Ensure it covers at least the viewport height */
  height: auto; /* Allow height to adjust based on content */
  background-image: url('../assets/light-page.jpg');
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px);
  filter: drop-shadow(2px 4px 6px black);
  position: relative; /* Position relative for the overlay */
  background-attachment: scroll; /* Ensure it scrolls with content */
  background-color: #d2b48c; /* Add your fallback background color */
}

/* Overlay for darkening the background */
.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

/* Ensure content is on top of the overlay */
.about .content {
  position: relative;
  z-index: 2;
  padding-top: 70px; /* Ensure content is below the navbar */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  align-items: center; /* Center vertically in the container */
}

/* Style adjustments for left content */
.left-content {
  flex: 1; /* Allow it to grow */
  max-width: 600px; /* Set a max width for larger screens */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content {
    flex-direction: row; /* Stack content on smaller screens */
    align-items: center; /* Center items */
    text-align: center; /* Center text */
  }

  .left-content {
    max-width: 100%; /* Allow full width on smaller screens */
    width: unset;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }


}

.hero-heading {
  font-size: 2rem;
}

.hero-paragraph {
  font-size: 1.2rem;
}

</style>

<script>
export default {
  name: 'AboutView',
  components: {}
}
</script>
